<template>
  <div class="hello">
    <transition name="el-zoom-in-center">
      <el-table
        v-if="showTable"
        :data="list"
        stripe
        border
        @row-dblclick="rowDbclick"
        style="width: 100%"
      >
        <el-table-column prop="id" label="弹窗id" width="80"> </el-table-column>
        <el-table-column prop="description" label="描述" width="200">
        </el-table-column>
        <el-table-column
          prop="img"
          label="拍脸图地址"
          width="180"
          align="center"
        >
          <!-- 图片的显示 -->
          <template slot-scope="scope">
            <img :src="scope.row.img" mode="widthFix" width="150" />
          </template>
        </el-table-column>
        <el-table-column prop="show_count" label="弹窗次数上限" width="100">
        </el-table-column>
        <el-table-column
          prop="start_time"
          label="开始时间"
          width="100"
          :formatter="dateFormat"
        >
        </el-table-column>
        <el-table-column
          prop="end_time"
          label="结束时间"
          width="100"
          :formatter="dateFormat"
        >
        </el-table-column>
        <el-table-column prop="weight" label="权重" width="80">
        </el-table-column>
        <el-table-column
          prop="op_type"
          label="操作类型"
          width="100"
          :formatter="opTypeFormat"
        >
        </el-table-column>
        <el-table-column prop="link" label="链接地址"> </el-table-column>
        <el-table-column prop="show_to_desc" label="展示人群">
        </el-table-column>
        <el-table-column prop="admin" label="创建人"> </el-table-column>
        <el-table-column
          prop="ct"
          label="创建时间"
          width="100"
          :formatter="dateFormat"
        >
        </el-table-column>
      </el-table>
    </transition>
    <el-dialog title="首页弹窗配置" :visible.sync="showForm">
      <el-form class="form" ref="form" :model="alertDetail" label-width="180px">
        <el-form-item label="选择图片上传：">
          <input type="file" accept="image/*" @change="photoUpload" />
          <span>(图片宽高560*620，大小不超过30k)</span>
        </el-form-item>
        <el-form-item label="弹窗id：">
          <el-input
            v-model="alertDetail.id"
            :disabled="true"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述：">
          <el-input
            v-model="alertDetail.description"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="拍脸图地址：">
          <el-input v-model="alertDetail.img" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="弹窗次数上限：">
          <el-input
            v-model="alertDetail.show_count"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="日期范围：">
          <el-date-picker
            v-model="pickerDate"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="权重：">
          <el-input
            v-model="alertDetail.weight"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作类型：">
          <el-select v-model="alertDetail.op_type" placeholder="">
            <el-option
              v-for="item in opTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接地址：">
          <el-input
            placeholder="可为空"
            v-model="alertDetail.link"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="展示条件：">
          <el-checkbox-group
            v-model="showToSelects"
            @change="showToCheckboxChange"
          >
            <el-checkbox
              v-for="item in showToOptions"
              :key="item.value"
              :label="item.label"
            >
            </el-checkbox>
          </el-checkbox-group>
          <!-- <el-select v-model="alertDetail.show_to" placeholder="">
                  <el-option
                  v-for="item in showToOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select> -->
        </el-form-item>
        <el-form-item v-if="showVersion" label="版本号：">
          <el-input
            placeholder="可为空"
            v-model="alertDetail.version_code"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="showWhiteList" label="白名单：">
          <el-input
            placeholder="可为空，userid英文逗号隔开"
            v-model="alertDetail.white_list_uids"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="showUserUrl" label="号码包链接：">
          <el-input
            v-model="alertDetail.user_url"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            alertDetail.isAdd ? "创建" : "立即修改"
          }}</el-button>
          <el-button @click="cancelSubmit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-button
      class="add-btn"
      type="primary"
      icon="el-icon-plus"
      circle
      @click="addBtnClick"
    >
    </el-button>
  </div>
</template>

<script>
import {
  getHomeAlertList,
  getShowToTypes,
  editPopWindow
} from '@/api/entranceApi.js';
import moment from 'moment';
import axios from 'axios';
import app from '@/config/app.js';

export default {
  name: 'HomeAlert',
  props: {},
  data () {
    return {
      showToTypes: [],
      list: [],
      showTable: false,
      showForm: false,
      alertDetail: {},
      pickerDate: '',
      opTypeOptions: [
        {
          value: 0,
          label: '没有操作'
        },
        {
          value: 5,
          label: '打开情侣愿望'
        },
        {
          value: 6,
          label: '打开纪念日'
        },
        {
          value: 201,
          label: '打开链接'
        },
        {
          value: 202,
          label: '打开链接（带token）'
        },
        {
          value: 211,
          label: '打开小程序页面链接'
        },
        {
          value: 1101,
          label: '打开时光'
        },
        {
          value: 1102,
          label: '打开相册'
        }
      ],
      showToSelects: [],
      showToOptions: [],
      showVersion: false,
      showWhiteList: false,
      showUserUrl: false
    };
  },

  methods: {
    dateFormat (row, column) {
      let date = row[column.property];
      if (date == undefined) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },

    opTypeFormat (row, column) {
      let type = row[column.property];
      let obj = {
        0: '没有操作',
        5: '打开情侣愿望',
        6: '打开纪念日',
        201: '打开链接',
        202: '打开链接（带token）',
        211: '打开小程序页面链接',
        1101: '打开时光',
        1102: '打开相册'
      };
      return obj[type];
    },

    getShowToTypes () {
      let that = this;
      getShowToTypes()
        .then((res) => {
          console.log('获取展示类型配置:', res);
          that.showToTypes = res.show_to_types;
          let tempArr = [];
          res.show_to_types.forEach((item) => {
            let obj = {};
            obj.value = item.show_to;
            obj.label = item.text;
            tempArr.push(obj);
          });
          that.showToOptions = tempArr;
          that.getListData();
        })
        .catch((error) => {
          console.log('获取展示类型配置失败:', error);
        });
    },

    showToCheckboxChange (e) {
      console.log('选中了条件:', e);
      this.handleFormItemShow(e);
    },

    handleFormItemShow (selects) {
      if (
        selects.indexOf('大于版本') > -1 ||
        selects.indexOf('大于等于版本') > -1 ||
        selects.indexOf('小于版本') > -1 ||
        selects.indexOf('小于等于版本') > -1 ||
        selects.indexOf('等于版本') > -1 ||
        selects.indexOf('不等于版本') > -1
      ) {
        this.showVersion = true;
      } else {
        this.showVersion = false;
      }

      if (selects.indexOf('白名单用户') > -1) {
        this.showWhiteList = true;
      } else {
        this.showWhiteList = false;
      }
      this.showUserUrl = selects.indexOf('号码包用户') > -1;
    },

    getListData () {
      let that = this;
      getHomeAlertList({ pageSize: 100 })
        .then((res) => {
          console.log('获取首页弹窗配置列表：', res);
          that.list = res.rows;
          that.showTable = true;
        })
        .catch((error) => {
          console.log('获取首页弹窗配置列表失败：', error);
        });
    },

    addBtnClick () {
      this.alertDetail = {
        isAdd: true,
        id: '',
        description: '',
        img: '',
        show_count: 5,
        weight: '',
        op_type: 0,
        link: ''
      };
      this.showToSelects = [];
      this.pickerDate = [];
      this.showVersion = false;
      this.showWhiteList = false;
      this.showUserUrl = false;
      this.showForm = true;
    },

    rowDbclick (row) {
      console.log('双击了某一行', row);
      this.alertDetail = {
        isAdd: false,
        id: row.id,
        description: row.description,
        img: row.img,
        show_count: row.show_count,
        weight: row.weight,
        op_type: row.op_type,
        link: row.link,
        version_code: row.version_code,
        white_list_uids: row.white_list_uids,
        user_url: row.user_url
      };
      let showToSelects = [];
      if (row.show_to_values && row.show_to_values.length > 0) {
        row.show_to_values.forEach((item) => {
          this.showToOptions.forEach((element) => {
            if (element.value == item) {
              showToSelects.push(element.label);
            }
          });
        });
      }
      this.showToSelects = showToSelects;
      this.handleFormItemShow(showToSelects);
      let start = new Date(row.start_time);
      let end = new Date(row.end_time);
      this.pickerDate = [start, end];
      this.showForm = true;
    },

    photoUpload (e) {
      console.log('1111111', e);
      let that = this;
      let file = e.target.files[0];
      if (file.size > 30 * 1024) {
        this.$message.error('图片大小不能超过30K');
        return;
      }
      let img = new Image();
      img.onload = function () {
        if (img.width != 560 || img.height != 620) {
          that.$message.error('图片宽高必须为560*620');
          return;
        }
        let param = new FormData(); // 创建form对象
        param.append('photo', file); // 通过append向form对象添加数据
        // param.append('chunk', '0') // 添加form表单中其他数据
        console.log(param.get('file')); // FormData私有类对象，访问不到，可以通过get判断值是否传进去
        let config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        };
        // 添加请求头
        axios
          .post(app.apiUrl + '/manage/life/group/photo', param, config)
          .then((res) => {
            console.log('上传图片成功:', res.data);
            if (res.data.result == 1) {
              that.$message({
                message: '图片上传成功了',
                type: 'success'
              });
              that.alertDetail.img = res.data.original_url;
            }
          });
      };
      img.src = URL.createObjectURL(file);
    },

    onSubmit () {
      let that = this;
      let alertDetail = this.alertDetail;
      let startTime = moment(that.pickerDate[0]).format('YYYY-MM-DD HH:mm:ss');
      let endTime = moment(that.pickerDate[1]).format('YYYY-MM-DD HH:mm:ss');
      let showToArr = [];
      that.showToSelects.forEach((item) => {
        that.showToOptions.forEach((element) => {
          if (element.label == item) {
            showToArr.push(element.value);
          }
        });
      });
      let showTo = JSON.stringify(showToArr);
      editPopWindow({
        id: alertDetail.id,
        type: 1,
        description: alertDetail.description,
        weight: alertDetail.weight,
        op_type: alertDetail.op_type,
        start_time: startTime,
        end_time: endTime,
        show_to: showTo,
        img: alertDetail.img,
        link: alertDetail.link,
        white_list_uids: alertDetail.white_list_uids,
        user_url: alertDetail.user_url,
        version_code: alertDetail.version_code,
        show_count: alertDetail.show_count
      })
        .then((res) => {
          console.log('编辑成功：', res);
          if (res.result == 1) {
            that.showForm = false;
            that.$message({
              message: '编辑成功',
              type: 'success'
            });
            that.getListData();
          } else {
            this.$message.error(res.error_msg);
          }
        })
        .catch((error) => {
          console.log('编辑失败：', error);
        });
    },

    cancelSubmit () {
      this.showForm = false;
    }
  },

  beforeCreate () {},
  created () {
    this.getShowToTypes();
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.add-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  right: 50px;
  bottom: 50px;
}
</style>
